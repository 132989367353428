import React, { useEffect, useState } from 'react'
import './index.css'
import Button from '../../Atoms/Button';

function WelcomeSection() {

  const [msgTimeout, setMsgTimeout] = useState<NodeJS.Timeout>();

  useEffect(() => {

    const changingMsgId = 'changing-message-ele'
    const cMsg = ['Web Design', 'Machine learning', 'Virtual Reality Apps']
    const messageElement = document.getElementById(changingMsgId);
    let index = 0;
    let msgIdx = 0;
    let uprint = 0;
    let maxUnderScoreShown = 18
    function displayNextCharacter() {
      if (messageElement) {
        if (msgIdx < cMsg.length && index < cMsg[msgIdx].length) {
          const char = cMsg[msgIdx].charAt(index);
          messageElement.textContent += char;
          index++;

          uprint = 0;
        }
        if (msgIdx < cMsg.length && index === cMsg[msgIdx].length) {
          if (uprint < maxUnderScoreShown) {
            uprint++;
            messageElement.textContent = cMsg[msgIdx]
          }
          if (uprint === maxUnderScoreShown) {
            msgIdx++;
            index = 0
            messageElement.textContent = ''
          }

        }
        if (msgIdx === cMsg.length) {
          msgIdx = 0
        }
        const timeOutS = setTimeout(displayNextCharacter, 100);
        setMsgTimeout(timeOutS)
      }
    }

    displayNextCharacter();


    return () => {
      clearTimeout(msgTimeout)
    }
  }, [])

  return (
    <section className="header-section" id="header-section" data-index={1}>
      
      <div className="header-box">
        <div className="header-text">
          <h1 className='main-heading center-element'>Hello There, my name is Deepak </h1>
          <h1> <span className="colorful-character">&#60;/&#62;</span> Software Engineer with 3 Years of Experience</h1>
          <h1 id="description-me"><span>&#127891;</span> CS Graduate Student and VR Developer at Virginia Tech</h1>
          <h2>I design, develop, and experiment with</h2>
        </div>

      </div>
      <h1 id="changing-message" className='changing-text center-element'><span id="changing-message-ele"></span></h1>
      <div className="social-links">
        <a className="fa-brands fa-linkedin" target="_blank" href="https://www.linkedin.com/in/deepakandgupta/"></a>
        <a className="fa-brands fa-github" target="_blank" href="https://github.com/deepakandgupta"></a>
      </div>
      <div className='scroll-down-container'>
        <Button title='&#11163;' rounded outline style={{ backgroundColor: 'black' }} onClick={() => {
          const elee = document.getElementById('projects-section');
          if (elee) {
            elee.scrollIntoView()
          }
        }}></Button>
      </div>
    </section>
  )
}

export default WelcomeSection
import React from 'react'
import './index.css'

interface HeaderProps{
    visibleSection: string | null
}

function Header(props: HeaderProps) {
    const {visibleSection} = props;
    return (
        <header className="fixed-navbar">
            <div className='nav-box'>
                <ul className="nav-items">
                    <li className={`nav-item ${visibleSection === "1" ? "current-header": ""}`} id="home"><a href="#header-section">Home</a></li>
                    <li className={`nav-item ${visibleSection === "2" ? "current-header": ""}`} id="projects"><a href="#projects-section">Projects</a></li>
                    {/* <li className="nav-item" id="highlights"><a href="#work-experience">Highlights</a></li> */}
                    <li className={`nav-item ${visibleSection === "3" ? "current-header": ""}`} id="contact"><a href="#contact-section">Contact</a></li>
                </ul>
            </div>
        </header>
    )
}

export default Header
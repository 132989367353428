import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import WelcomeSection from './Components/WelcomeSection';
import ProjectsSection from './Components/ProjectsSection';
import Header from './Components/Header';
import AnimatedBG from './Components/AnimatedBG';
import ContactPage from './Components/ContactPage';

function App() {
  const [visibleSection, setVisibleSection] = useState<string | null>(null);
  const mainContentRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = () => {
    const sections = document.querySelectorAll('section');
    let currentSection = null;
    
    sections.forEach((section) => {
      const rect = section.getBoundingClientRect();
      const containerHeight = document.documentElement.clientHeight;

      if (rect.top >= -0.05 * containerHeight && rect.bottom <= containerHeight + 0.05 * containerHeight) {
        currentSection = section.getAttribute('data-index');
      }
    });
    setVisibleSection(currentSection);
  };

  useEffect(() => {
    const mainContent = mainContentRef.current;

    if (mainContent) {
      mainContent.addEventListener('scroll', handleScroll);
      handleScroll(); // Initial check
    }

    return () => {
      if (mainContent) {
        mainContent.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <div className="App">
      <AnimatedBG />
      <Header visibleSection={visibleSection}/>
      <div className='content' id='main-content' ref={mainContentRef}>
        <WelcomeSection />
        <ProjectsSection/>
        <ContactPage/>
      </div>
    </div>
  );
}

export default App;
